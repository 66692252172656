import { useDispatch } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { trackPageView, updateUDOObject } from "hig-tealium-core";
import { useSearchParams, useLocation, Link } from "react-router-dom";

import {
  somethingWentWrong,
  somethingWentWrongDescription,
  returnToSearch,
  closeAndReturnToIcon,
  pageTag,
} from "shared/messages";
import { axiosPostInstance } from "shared/axiosInstance";
import { updatePageName } from "redux/actions/pageNameAction";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import { getSearchResultsURL, getReportDataURL } from "shared/apiEndpoints";
import LoadingIndicator from "components/LoadingIndicator/LoadingIndicator";

import MaxLimitsTable from "./MaxLimits/MaxLimitsTable";
import AppetiteGuide from "./AppetiteGuide/AppetiteGuide";
import MandatoryForms from "./MandatoryForms/MandatoryForms";
import ClassDescription from "./ClassDescription/ClassDescription";
import ClassDetailsExternal from "./ClassDetails/External/ClassDetailsExternal";
import ClassDetailsInternal from "./ClassDetails/Internal/ClassDetailsInternal";

import "./ClassDetailPage.scss";

const ClassDetailPage = () => {
  const detailsPageRef = useRef(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState(null);
  const userType = sessionStorage.getItem("userType");
  const [searchResultsData, setSearchResultsData] = useState(null);

  const searchParam = {
    classCode: searchParams.get("ClassCode") ?? null,
    lobcd: searchParams.get("lob") ?? null,
    riskState: searchParams.get("RiskState") ?? null,
    segmentIndicator: searchParams.get("SegmentIndicator") ?? null,
    producerCode: searchParams.get("ProducerCode") ?? null,
    COBId: searchParams.get("COBId") ?? null,
  };

  /**
   * Determines what zip code value (if any) should be used in the getSearchResults or getReportData payloads
   * @return {String} zip code value for payload
   */
  const getZipCode = () => {
    return location.state?.from === "Search Page" &&
      localStorage.getItem("searchType") === "Business"
      ? localStorage.getItem("zipCode")
      : "";
  };

  const getSearchResultsPayload = {
    returnAssocClass: "Y",
    sentinelIndicator: sessionStorage.getItem("sentinelInd") ?? "Y",
    stateCd: searchParam.riskState,
    searchId: searchParam.classCode,
    searchType: "CLASS_CODE_SEARCH_EQUAL",
    producerCode: searchParam.producerCode,
    segmentCd: searchParam.segmentIndicator,
    lobcd: searchParam?.lobcd?.toUpperCase(),
    agentViewIndicator: userType === "internal" ? "N" : "Y",
    zipCode: getZipCode(),
  };

  useEffect(() => {
    setError(false);
    axiosPostInstance()
      .post(getSearchResultsURL, getSearchResultsPayload)
      .then((res) => {
        const data = res.data;
        setSearchResultsData(data);

        const getReportDataPayload = {
          classCd: searchParam.classCode,
          stateCd: searchParam.riskState,
          searchType: "COBID_SEARCH_EQUAL",
          producerCode: searchParam.producerCode,
          segmentCd: searchParam.segmentIndicator,
          classCodeType:
            data?.searchResultsLineItemList?.[0]?.businessClassCdTypeName,
          reportId:
            searchParam.COBId ||
            data?.searchResultsLineItemList?.[0]?.businessClassCaseId,
          zipCode: getZipCode(),
        };

        axiosPostInstance()
          .post(getReportDataURL, getReportDataPayload)
          .then((res) => {
            const data = res.data;
            setReportData(data);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setError(true);
          });
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  }, []);

  /* istanbul ignore next */
  useEffect(() => {
    // on load of page set the UDO object for the UID and the user type
    updateUDOObject({
      ebcid:
        sessionStorage.getItem("ebcUID") ??
        sessionStorage.getItem("internalUID"),
      user_relationship: sessionStorage.getItem("userType"),
    });

    if (searchResultsData) {
      updateUDOObject({
        class_id: searchResultsData?.searchResultsLineItemList?.[0]?.classCode,
        state: searchParams.get("RiskState") ?? null,
        ind_desc_ol_comm:
          searchResultsData?.searchResultsLineItemList?.[0]?.marketGroupName,
      });
      detailsPageRef.current = true;
    }

    if (detailsPageRef.current) {
      trackPageView("Class Details Page");
    }
  }, [searchResultsData]);

  /* istanbul ignore next */
  useEffect(() => {
    sessionStorage.setItem("classDetailsPage", true);
    // used for updating the 'Something went wrong' link
    if (document.referrer !== "") {
      localStorage.setItem("previousPage", document.referrer);
    }

    dispatch(updatePageName("classDetails"));

    /*
        this is to combat a known bug with useNavigate where when going to a new page the browser remembers
        where the scroll position is of the previous page and sets the new page to that position
      */
    window.scrollTo(0, 0);
  }, []);

  const searchData =
    searchResultsData?.searchResultsLineItemList?.find(
      (searchResultsItem) =>
        searchResultsItem.classCode === searchParam.classCode &&
        searchResultsItem.businessClassCaseId === searchParam.COBId
    ) ||
    searchResultsData?.searchResultsLineItemList?.[0] ||
    {};

  const previousPage = localStorage.getItem("previousPage");

  if (error)
    return (
      <div>
        <ErrorMessage
          linkAddress={previousPage?.includes("esubmissions") ? null : "/"}
          showLink={true}
          utilizeNavLink={true}
          linkText={
            previousPage?.includes("esubmissions")
              ? closeAndReturnToIcon
              : returnToSearch
          }
          error={somethingWentWrong}
          icon="icon icon-alert-warning"
          message={somethingWentWrongDescription}
        />
      </div>
    );

  return (
    <div
      data-testid="ClassDetailPageId"
      className="class_detail_page_container"
    >
      {loading ? (
        <LoadingIndicator text="Loading..." fullScreen />
      ) : (
        <>
          <div className="container mt-4">
            {location.state?.from === "Search Page" && (
              <div className="mb-sm-4 mb-2 d-print-none">
                <Link
                  to="/"
                  className="return_to_results_link text-decoration-none "
                  data-dl={`{"event_parent": "${pageTag}", "event_type": "Link Click", "event_id": "Return to results link", "da_track": "true"}`}
                >
                  &lt; Return to results
                </Link>
              </div>
            )}

            <ClassDescription
              searchData={searchData}
              producerCode={searchParams.get("ProducerCode")}
            />
            <AppetiteGuide
              searchData={searchData}
              reportData={reportData}
              segmentCd={searchParam.segmentIndicator}
            />
            <MaxLimitsTable searchData={searchData} />
            {/* Styles are so different between internal and external user for Class Details it was easier to make 2 different components */}
            {userType === "external" ? (
              <ClassDetailsExternal
                searchData={searchData}
                reportData={reportData}
              />
            ) : (
              <ClassDetailsInternal
                searchData={searchData}
                reportData={reportData}
              />
            )}
            {/* Mandatory Forms are hidden for September release per business.  To bring this back remove comment below */}
            {/* {userType === "internal" &&
              (reportData?.forms ?? []).length > 0 && (
                <MandatoryForms formsData={reportData?.forms} />
              )} */}
          </div>
        </>
      )}
    </div>
  );
};

export default ClassDetailPage;
